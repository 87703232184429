import * as React from "react";
import { Link } from "gatsby-theme-material-ui";
import Layout from "src/components/Layout";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Paragraph from "src/components/Paragraph";
import { Helmet } from "react-helmet";

const NotFoundPage = ({ location }) => {
  React.useEffect(() => {
    let pathname = location.pathname;

    if (pathname.startsWith("/blog/")) {
      window.location = "/community-stories";
    }
  }, [location]);

  return (
    <Layout>
      <Container sx={{ py: 10 }}>
        <Helmet>
          <title>Not found</title>
        </Helmet>

        <Typography variant="h1">Page not found</Typography>
        <Paragraph>
          Sorry we couldn’t find what you were looking for.{" "}
          <Link to="/">Go home</Link>.
        </Paragraph>
      </Container>
    </Layout>
  );
};

export default NotFoundPage;
